import React from 'react';
import { useInView } from 'react-intersection-observer';
import phoneImg from '../../assets/chekouuu.png';

const SecurePayments = () => {
    const { ref, inView } = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.2, // Trigger when 20% of the component is in view
    });

    return (
        <div
            ref={ref}
            className={`flex flex-col-reverse md:flex-row mx-auto justify-between bg-white rounded-lg shadow-lg my-3 md:my-12 px-6 font-inter w-[90%] md:w-[70%] transform transition-transform duration-1000 ease-out ${
                inView ? 'scale-100' : 'scale-90 opacity-0'
            }`}
        >
            {/* Image Section */}
            <div className='relative flex h-[300px] md:h-[500px] w-full md:w-1/2 bg-grey rounded-lg justify-end overflow-visible'>
                <div className='absolute bottom-0 w-full'>
                    <img
                        src={phoneImg}
                        alt='phone'
                        className='w-[53%] h-auto mx-auto md:w-[65%] object-contain -mt-8'
                    />
                </div>
            </div>

            {/* Text Section */}
            <div className='flex flex-col justify-start py-8 w-full md:w-[50%]'>
                <h1 className='text-black text-2xl font-bold text-center md:text-left'>Secure Payments</h1>

                <h3 className='text-gray-500 text-sm md:text-md font-normal py-1 md:py-4 text-center md:text-left'>
                    Enjoy a secure checkout with multiple payment options, including mobile money and credit cards
                </h3>
                <div className='text-black text-lg font-normal py-2 px-12 mt-8 mx-auto md:mx-0 bg-transparent border border-black rounded-full text-center w-fit hover:scale-105 hover:bg-black hover:text-white cursor-pointer'>
                    Learn more
                </div>
            </div>
        </div>
    );
};

export default SecurePayments;
