import {React} from 'react';

const Downloads = () => {
    return (
        <div className='flex flex-col font-inter my-4 md:my-0'>
            <p className='font-semibold text-lg text-black'>Downloads</p>

            <nav className='flex flex-col w-fit pt-2 md:pt-6'>
                <ul className='flex flex-col gap-y-2 text-sm md:gap-y-3'>
                    <li><a href='#' className='text-blue-400 font-light hover:underline'>For iPhone</a></li>
                    <li><a href='#' className='text-blue-400 font-light hover:underline'>For Android</a></li>
                </ul>

            </nav>

        </div>
    )
}

export default Downloads