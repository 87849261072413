import { React } from 'react';
import { RiShoppingBag2Fill } from "react-icons/ri";
import { BsShopWindow } from "react-icons/bs";

const Intro = () => {
    return (
        <div className='w-full md:w-[800px] mx-auto flex flex-col mx-auto text-black my-8 md:my-12 px-4' id='home'>
            <h1 className='text-4xl md:text-8xl font-inter font-black text-center'>
                BUY <span className='font-semibold'> AND </span> SELL,
            </h1>
            <h1 className='text-3xl md:text-5xl font-inter font-black text-center py-4'>
                FAST <span className='font-normal'>AND</span>  SAFE
            </h1>
            <p className='text-sm md:text-lg text-center py-2 md:py-6 font-inter'>
                <span className='font-bold'>Experience Rapid, Secure Transactions </span>
                Buy and Sell Fast with Complete Confidence, Ensuring Every Deal is Safe and Hassle-Free on
                <span className='font-bold'> Venda</span>
            </p>
            {/* <p className='text-sm md:text-lg text-center font-inter'>
                Ensuring Every Deal is Safe and Hassle-Free on
                <span className='font-bold'> Venda</span>
            </p> */}

            <div className='hidden md:flex gap-x-10 items-center mx-auto my-8 font-inter'>
                <div className='flex gap-x-3 justify-between items-center rounded-full py-4 px-8 bg-black text-white cursor-pointer hover:scale-105'>
                    <RiShoppingBag2Fill size={24} />
                    <p className='text-lg'>Shop On Venda</p>
                </div>

                <div className='flex gap-x-3 justify-between items-center rounded-full py-4 px-8 bg-transparent border border-black text-black cursor-pointer hover:scale-105'>
                    <BsShopWindow size={24} />
                    <p className='text-lg text-black'>Sell On Venda</p>
                </div>
            </div>
        </div>
    )
}

export default Intro;