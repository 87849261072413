import {React} from 'react';

const Nav = () => {
    return (
        <div className='flex flex-col font-inter my-4 md:my-0'>
            <nav className='flex flex-col w-fit'>
                <ul className='flex flex-col text-sm gap-y-2 md:gap-y-3'>
                    <li><a href='#home' className='text-blue-400 font-light hover:underline'>Home</a></li>
                    <li><a href='#features' className='text-blue-400 font-light hover:underline'>Features</a></li>
                </ul>

            </nav>

        </div>
    )
}

export default Nav