import React, { useState } from 'react';
import Logo from '../assets/venda.png';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState("");


    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    return (
        <nav className={`w-[95%] md:w-fit px-4 bg-white bg-opacity-95 border border-grey shadow-lg ${isOpen ? 'rounded-xl' : 'rounded-full'} mx-auto my-4 font-inter sticky top-0 z-50`}>
            <div className="flex justify-between w-full md:w-[800px] items-center h-16">
                {/* Logo Section */}
                <div>
                    <a href='#'>
                        <div className="w-28 h-28 md:mr-6">
                            <img src={Logo} alt="Logo" className="object-contain w-full h-full" />
                        </div>
                    </a>
                </div>

                {/* Menu Button (Mobile) */}
                <button onClick={toggleMenu} className="ml-auto sm:hidden text-black">
                    {isOpen ? (
                        <IoMdClose size={24} />
                    ) : (
                        <FaBars size={24} />
                    )}
                </button>

                {/* Nav Links (Larger Screens) */}
                <div>
                    <ul className="hidden sm:flex space-x-6 text-black font-normal">
                        <li>
                            <a href="#features" onClick={() => setActiveTab("features")}>
                                <div className={`rounded-full px-2 py-1 ${activeTab === "features" ? "font-bold" : "bg-none"}`}>
                                    Features
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#contacts" onClick={() => setActiveTab("contacts")}>
                                <div className={`rounded-full px-2 py-1 ${activeTab === "contacts" ? "font-bold" : "bg-none"}`}>
                                    Contacts
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#partners" onClick={() => setActiveTab("partners")}>
                                <div className={`rounded-full px-2 py-1 ${activeTab === "partners" ? "font-bold" : "bg-none"}`}>
                                    Partners
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#faq" onClick={() => setActiveTab("faq")}>
                                <div className={`rounded-full px-2 py-1 ${activeTab === "faq" ? "font-bold" : "bg-none"}`}>
                                    FAQ
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

                {/* Download Button (Larger Screens) */}
                <a href='#download'>
                <div className='hidden sm:flex bg-black rounded-full py-2 px-4 my-3 font-semibold text-md hover:scale-105 text-white cursor-pointer'>
                    Download Venda
                </div>
                </a>
            </div>

            {/* Mobile nav */}
            {isOpen && (
                <div className="sm:hidden mt-4">
                    <ul className="flex flex-col space-y-4 text-black font-normal text-center">
                        <a href="#features">
                            <li className="hover:font-bold flex justify-center">
                                Features
                            </li>
                        </a>
                        <a href="#contacts">
                            <li className="hover:font-bold flex justify-center">
                                Contacts
                            </li>
                        </a>
                        <a href="#partners">
                            <li className="hover:font-bold flex justify-center">
                                Partners
                            </li>
                        </a>
                        <li>
                            <a href="#download">
                                <div className='bg-black text-white rounded-full py-2 px-4 my-3 font-semibold text-lg hover:scale-105 w-full mx-auto'>Download Venda</div>
                            </a>
                        </li>
                    </ul>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
