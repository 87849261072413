import { React } from 'react'
import Navbar from '../components/navbar';
import Intro from '../components/intro';
import Download from '../components/download';
import Features from '../components/features';
import Footer from '../components/footer';

const Home = () => {
    return (
        <>
            <div className='w-full md:w-[1200px] mx-auto'>
                <Navbar />
                <Intro />
                <Download />
                <Features />
            </div>
            <div className='w-full bg-white'>
                <Footer />
            </div>
        </>
    )
}

export default Home;