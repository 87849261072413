import React from 'react';
import { useInView } from 'react-intersection-observer';
import phoneImg from '../assets/Feature1.png';
import appStore from '../assets/Apple.png';
import googlePlay from '../assets/Google.png';
import { FaApple } from "react-icons/fa";
import { FaGooglePlay } from "react-icons/fa";

const Download = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.6,
    });

    return (
        <div ref={ref} className={`flex flex-col md:flex-row mx-auto justify-center items-center ${inView ? '' : 'md:flex-col md:pt-10'}`} id='download'>

            {/* Animated Phone Image */}
            <div className={`flex h-[500px] w-1/2 bg-grey rounded-lg justify-end transition-transform duration-[1500ms] ease-out perspective-1000 ${inView ? 'scale-100 translate-y-0 rotate-x-0 opacity-100' : 'mx-auto scale-150 translate-y-10 opacity-100 rotate-x-45'}`}>
                <div className='flex justify-end w-full'>
                    <img src={phoneImg} alt="phone" className='w-full h-full object-contain ml-auto' />
                </div>
            </div>

            {/* Text Section */}
            <div className={`flex flex-col mx-auto justify-start font-inter transition-opacity transition-transform duration-[1500ms] ease-out ${inView ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
                <h3 className='text-black text-3xl text-center font-semibold'>
                    Used & Trusted By
                </h3>

                <h1 className='text-black text-6xl text-center font-black'>Millions</h1>

                <h3 className='text-black text-3xl text-center font-semibold'>
                    Download the App
                </h3>

                <div className='flex flex-col md:flex-row items-center gap-x-4 justify-center mx-auto my-8'>

                    <a href='#' className='w-1/2 md:w-1/4 my-1'>
                    <img src={appStore} alt="phone" className='w-full h-auto object-contain hover:scale-105' />
                    </a>

                    <a href='#' className='w-1/2 md:w-1/4 my-1'>
                    <img src={googlePlay} alt="phone" className='w-full h-auto object-contain hover:scale-105' />
                    </a>

                </div>
            </div>

        </div>
    );
};

export default Download;
