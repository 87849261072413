import {React} from 'react';

const Socials = () => {
    return (
        <div className='flex flex-col font-inter my-4 md:my-0'>
            <p className='font-semibold text-lg text-black'>Socials</p>

            <nav className='flex flex-col w-fit pt-2 md:pt-6'>
                <ul className='flex flex-col text-sm gap-y-2 md:gap-y-3'>
                    <li><a href='#' className='text-blue-400 font-light hover:underline'>Facebook</a></li>
                    <li><a href='#' className='text-blue-400 font-light hover:underline'>Instagram</a></li>
                    <li><a href='#' className='text-blue-400 font-light hover:underline'>Twitter/X</a></li>
                </ul>

            </nav>

        </div>
    )
}

export default Socials