import React from 'react';
import { useInView } from 'react-intersection-observer';
import phoneImg from '../../assets/track.png';

const Tracking = () => {
    const { ref, inView } = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.2, // Trigger when 20% of the component is in view
    });

    return (
        <div
            ref={ref}
            className={`flex flex-col-reverse md:flex-row mx-auto justify-between bg-white rounded-lg shadow-lg my-3 md:my-12 px-6 font-inter w-[90%] md:w-[70%] transform transition-transform duration-1000 ease-out ${
                inView ? 'scale-100' : 'scale-90 opacity-0'
            }`}
        >
            {/* Image Section */}
            <div className='relative flex h-[400px] md:h-[500px] w-full md:w-1/2 bg-grey rounded-lg justify-center md:justify-start overflow-visible'>
                <div className='absolute bottom-0 md:static w-full flex justify-center md:justify-start'>
                    <img
                        src={phoneImg}
                        alt='phone'
                        className='w-[70%] md:w-[65%] h-auto object-contain -mt-8'
                    />
                </div>
            </div>

            {/* Text Section */}
            <div className='flex flex-col justify-start py-8 w-full md:w-[50%]'>
                <h1 className='text-black text-2xl font-bold text-center md:text-left'>Real-time Tracking</h1>

                <h3 className='text-gray-500 text-sm md:text-md text-center md:text-left font-normal py-1 md:py-4'>
                    Keep track of all your orders in one, convenient place. Stay up-to-date with notifications and real-time tracking from order to your doorstep.
                </h3>

                <div className='text-black text-lg font-normal py-2 px-12 mt-4 md:mt-8 bg-transparent border border-black rounded-full text-center w-fit hover:scale-105 hover:bg-black hover:text-white cursor-pointer mx-auto md:mx-0'>
                    Get the App
                </div>
            </div>
        </div>
    );
};

export default Tracking;
