import React from 'react';
import { useInView } from 'react-intersection-observer';
import phoneImg from '../../assets/Hand2.png';

const PersonalizedShopping = () => {
    const { ref, inView } = useInView({
        triggerOnce: true, 
        threshold: 0.2, 
    });

    return (
        <div
            ref={ref}
            className={`flex flex-col md:flex-row mx-auto justify-between bg-white rounded-lg shadow-lg my-3 md:my-20 px-6 font-inter w-[90%] md:w-[70%] transform transition-transform duration-1000 ease-out ${
                inView ? 'scale-100' : 'scale-90 opacity-0'
            }`}
        >
            <div className='flex flex-col justify-start py-8 w-full md:w-[33%]'>
                <h1 className='text-black text-2xl font-bold text-center'>Personalized Shopping</h1>

                <h3 className='text-gray-500 text-md font-normal'>
                    Tailored Recommendations based on your shopping habits and preferences
                </h3>
                <div className='text-black text-lg font-normal py-2 px-8 md:px-16 mt-4 md:mt-8 mx-auto md:mx-0 bg-transparent border border-black rounded-full text-center hover:scale-105 hover:bg-black hover:text-white cursor-pointer w-fit'>
                    Get the App
                </div>
            </div>

            <div className='relative flex h-[300px] md:h-[500px] w-full md:w-1/2 bg-grey rounded-lg justify-end overflow-visible'>
                <div className='absolute bottom-0 w-full'>
                    <img
                        src={phoneImg}
                        alt='phone'
                        className='w-[80%] md:w-[100%] h-auto object-contain -mt-8'
                    />
                </div>
            </div>
        </div>
    );
};

export default PersonalizedShopping;
