import { React } from 'react';
import PersonalizedShopping from './features/personalized_shoppind';
import Tracking from './features/tracking';
import TrustedSellers from './features/trusted_sellers';
import SecurePayments from './features/secure_payments';

const Features = () => {

    return (
        <div className="w-full flex flex-col mx-auto text-black my-12 font-inter" id='features'>

            <div className='text-black text-4xl font-bold text-center'>WHY US</div>

            <PersonalizedShopping />
            <Tracking />
            <TrustedSellers />
            <SecurePayments />


        </div>
    )
}

export default Features;