import { React } from 'react'
import HelpSupport from './footer/help_support'
import Downloads from './footer/downloads'
import Socials from './footer/socials'
import Nav from './footer/nav'
import Logo from '../assets/venda.png'

const Footer = () => {

    return (
        <div className='md:w-[1200px] mx-auto'>
            <div className='flex flex-col mx-auto p-4 md:p-8 font-inter'>
                <div className="w-28 h-28">
                    <img src={Logo} alt="Logo" className="object-contain w-full h-full" />
                </div>

                <div className='w-full flex flex-col md:flex-row gap-x-10 items-start justify-between'>
                    <HelpSupport />
                    <Downloads />
                    <Socials />
                    <Nav />
                </div>
            </div>

        </div>
    )
}

export default Footer